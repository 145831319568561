import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center",
  style: `height: 200px`
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_Splide = _resolveComponent("Splide")!

  return (_ctx.single)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("img", {
          src: _ctx.image,
          class: "object-none cursor-pointer rounded",
          alt: _ctx.item
        }, null, 8, ["src", "alt"])
      ]))
    : (_openBlock(), _createBlock(_component_Splide, {
        key: 1,
        options: {
      rewind: true,
      type: 'slide',
      lazyLoad: 'nearby',
      focus: 'center',
      arrows: _ctx.newData.length > 1,
      pagination: _ctx.newData.length > 1,
      classes: {
        prev: 'splide__arrow--prev prev-arrow-dashboard',
        next: 'splide__arrow--next next-arrow-dashboard'
      }
    },
        class: "mt-5 w-11/12 ml-8",
        style: `height: 170px`
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.newData, (item, index) => {
            return (_openBlock(), _createBlock(_component_SplideSlide, { key: index }, {
              default: _withCtx(() => [
                _createVNode("div", {
                  class: "flex justify-center",
                  style: `height: 120px`,
                  onClick: item.action
                }, [
                  (_ctx.isCustom && index === 0)
                    ? _renderSlot(_ctx.$slots, "default", { key: 0 })
                    : (_openBlock(), _createBlock("img", {
                        key: 1,
                        src: _ctx.formatSourceImage(item.image),
                        class: "cursor-pointer rounded-lg w-full",
                        alt: item
                      }, null, 8, ["src", "alt"]))
                ], 8, ["onClick"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["options"]))
}