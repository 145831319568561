
import { Vue, Options, prop } from "vue-class-component";

class Props {
  data = prop<Array<any>>({
    default: [],
    type: Array
  });
  maxHeight = prop<string>({
    default: "",
    type: String
  });
  isCustom = prop<boolean>({
    default: false,
    type: Boolean
  });
  single = prop<boolean>({
    default: false,
    type: Boolean
  });
  image = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  name: "PreviewImage",
  emits: ["update:modelValue", "close"]
})
export default class extends Vue.with(Props) {
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", this.onClickEscape);
  }

  unmounted() {
    document.removeEventListener("keydown", this.onClickEscape);
  }

  onClickEscape(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.onClose()
    }
  }

  onClose() {
    this.$emit("update:modelValue", false);
    this.$emit("close");
  }

  get newData() {
    const temp: string[] = this.data;

    if (this.isCustom) {
      temp.unshift("");
      return temp;
    }
    return temp;
  }

  formatSourceImage(image: string) {
    if (image.includes("http")) {
      return image;
    }
    return require(`@/app/ui/assets/images/${image}`);
  }
}
